import {inject} from 'vue';
import {FETCH_LINKS, PRISMIC_CLIENT, USER_LOCALE} from '~/app/keys';
import {createError, useLogger, useSpanWrap, usePrismicClient, useLocale} from '#imports';
import {predicate} from '@prismicio/client';
import type {PrismicDocument, Query} from '@prismicio/types';

/**
 * Search documents from Prismic repository.
 * @param type  Document type.
 */

export type PrismicSearchParameters = {
    type: string;
    pageSize?: number;
    category?: string;
    audience?: string;
    tags?: string[];
    excludes?: string[];
    predicates?: string[];
    orderings?: {field: string; direction: 'asc' | 'desc'}[];
    page?: number;
    search?: string;
};

export default async function usePrismicSearch<T extends PrismicDocument>(
    {type, category, tags, excludes, audience, pageSize = 3, predicates = [], orderings = [], page = 1, search}: PrismicSearchParameters,
    onDemand = false, // inject is not available outside setup function, use this instead
): Promise<Query<T>> {
    const logger = useLogger();
    const client = onDemand ? usePrismicClient() : inject(PRISMIC_CLIENT);
    const locale = onDemand ? useLocale().locale.value : inject(USER_LOCALE);
    const lang = locale?.replace('_', '-') ?? 'fr-FR';
    try {
        const filterPredicates: string[] = [];
        if (category) {
            filterPredicates.push(predicate.at(`my.${type}.category`, category));
        }
        if (audience) {
            filterPredicates.push(predicate.any(`my.${type}.audience`, [audience, 'all']));
        }
        if (tags && tags.length > 0) {
            filterPredicates.push(predicate.at('document.tags', tags));
        }
        if (excludes && excludes.length > 0) {
            filterPredicates.push(...excludes.map((id) => predicate.not('document.id', id)));
        }
        if (search) {
            filterPredicates.push(predicate.fulltext('document', search));
        }
        if (!client) {
            throw createError({message: `Prismic client not found for search`, statusCode: 404});
        }

        const response = await useSpanWrap(
            () =>
                client?.getByType(type, {
                    lang: locale,
                    pageSize,
                    predicates: [...filterPredicates, ...predicates],
                    orderings: [...orderings, {field: type === 'event' ? 'my.event.date' : `my.${type}.releaseDate`, direction: 'desc'}],
                    page,
                    fetchLinks: FETCH_LINKS,
                }),
            {httpMethod: 'prismic.get', kind: 'prismic', name: `[prismic] ${tags}`},
        );
        return response as Query<T>;
    } catch (error) {
        logger.info(`Could not search documents from Prismic with parameters: [${type}, ${lang}, ${category}, ${tags}, ${page}, ${pageSize}].`);
        return Promise.reject(error);
    }
}
